const initUrl = new URL(window.location);
let currentPage = initUrl.searchParams.get('page')
    ? parseInt(initUrl.searchParams.get('page'), 10)
    : 1;

/**
 *
 * @param {Number} page - number derived from url param 'page'
 * @param {URL} currentURL - url object
 * @returns - templated DOM object for insertion/replacement
 */
const setPreviousLink = (page, currentURL) => {
    const url = new URL(currentURL);
    url.searchParams.set('page', page - 1);
    const template = document.createElement('a');
    template.setAttribute('href', url);
    template.setAttribute('rel', 'prev');
    template.textContent = 'Previous Page';
    return template;
};

/**
 *
 * @param {Number} page - number derived from url param 'page'
 * @param {URL} currentURL - url object
 * @returns - templated DOM object for insertion/replacement
 */
const setNextLink = (page, currentURL) => {
    const url = new URL(currentURL);
    url.searchParams.set('page', page + 1);
    const template = document.createElement('a');
    template.setAttribute('href', url);
    template.setAttribute('rel', 'next');
    template.textContent = 'Next Page';
    return template;
};

/**
 * @description - updates canonical tag as needed || or create one and append it if there is none existing
 */

const updateCanonicalUrl = () => {
    const url = new URL(window.location);
    const canonicalUrl = new URL(window.location);

    url.searchParams.keys().forEach((key) => {
        if (key !== 'page') {
            canonicalUrl.searchParams.delete(key);
        }
    });

    const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
    if (canonicalLinkTag) {
        canonicalLinkTag.setAttribute('href', canonicalUrl.href);
    } else {
        const newCanonicalLinkTag = document.createElement('link');
        newCanonicalLinkTag.setAttribute('rel', canonicalUrl.href);
        document.head.appendChild(newCanonicalLinkTag);
    }
};

/**
 * @description - updating DOM content based on the presence of 'show more button' and or 'page' in params
 *
 */

const paginationHelper = () => {
    const url = new URL(window.location);
    const page = url.searchParams.get('page') ? parseInt(url.searchParams.get('page'), 10) : 1;
    const seoPaging = document.querySelectorAll('.seo-paging');
    const showMoreButton = document.querySelector('.show-more button');
    const nextLink = setNextLink(page, url);
    const previousLink = setPreviousLink(page, url);

    if (page !== currentPage) {
        // update the canonical
        updateCanonicalUrl(url);
        currentPage = page;
    }

    seoPaging.forEach((elem) => {
        // empty the node at first
        /* eslint-disable-next-line no-param-reassign */
        elem.innerHTML = '';

        if (showMoreButton && $('.show-more button').is(':visible')) {
            elem.appendChild(nextLink);
        }

        if (page && page > 1) {
            elem.prepend(previousLink);
        }

        if (!$('.show-more button').is(':visible')) {
            elem.remove(nextLink);
        }
    });
};

export { paginationHelper };
export default paginationHelper;
