$(document).ready(() => {
    const resultCount = Number($('.search-results').data('searchResultsCount'));

    $('.content-tab-search-js').click(() => {
        $('.content-tab__search-form').submit();
    });

    $('.content-tab-shop-js').click(function () {
        $('.content-tab-article-js').removeClass('selected');
        $(this).addClass('selected');
        $('.hero').show();
        $('#product-search-results').show();
        $('#content-search-results-pane').hide();
        $('.no-search-results__no-product-results').show();
        $('.content-no-results-slots').hide();
        $('.product-no-results-slots').show();
        $('.shopping-tool__wrapper.shopping-tool__search-results .shopping-tool').show();
    });

    $('.content-tab-article-js').click(function () {
        $('.content-tab-shop-js').removeClass('selected');
        $(this).addClass('selected');
        $('.hero').hide();
        $('#product-search-results').hide();
        $('#content-search-results-pane').show();
        $('.no-search-results__no-product-results').hide();
        $('.content-no-results-slots').show();
        $('.product-no-results-slots').hide();
        $('.shopping-tool__wrapper.shopping-tool__search-results .shopping-tool').hide();
    });

    window.setTimeout(() => {
        if (resultCount === 0 && $('.content-search-results__result').length > 0) {
            $('.content-tab-article-js').trigger('click');
        } else {
            $('.content-tab-shop-js').trigger('click');
        }
    }, 0);
});
